import { priceData } from '../containers/ListingPage/ListingPage.shared';
import { useConfiguration } from '../context/configurationContext';
import { types as sdkTypes } from '../util/sdkLoader';
import * as validators from '../util/validators';
import { formatMoney } from './currency';

const { UUID, Money } = sdkTypes;

export const getHighestBidPriceLabel = (listing, intl) => {
  const config = useConfiguration();

  const mainPrice = listing?.attributes?.price;
  const highestBidAmount = listing?.attributes?.publicData?.highestBidAmount;
  const highestBigPrice = highestBidAmount && new Money(highestBidAmount, config.currency);

  const { formattedPrice } = priceData(highestBigPrice || mainPrice, config.currency, intl);
  return 'Highest bid: ' + formattedPrice;
};

export const getCustomPriceValidators = (
  listingMinimumPriceSubUnits,
  marketplaceCurrency,
  intl
) => {
  const priceRequiredMsgId = { id: 'EditListingPricingAndStockForm.priceRequired' };
  const priceRequiredMsg = intl.formatMessage(priceRequiredMsgId);
  const priceRequired = validators.required(priceRequiredMsg);

  const minPriceRaw = new Money(listingMinimumPriceSubUnits, marketplaceCurrency);
  const minPrice = formatMoney(intl, minPriceRaw);
  const priceTooLowMsgId = { id: 'EditListingPricingAndStockForm.priceTooLow' };
  const priceTooLowMsg = intl.formatMessage(priceTooLowMsgId, { minPrice });
  const minPriceRequired = validators.moneySubUnitAmountAtLeast(
    priceTooLowMsg,
    listingMinimumPriceSubUnits
  );

  return listingMinimumPriceSubUnits
    ? validators.composeValidators(priceRequired, minPriceRequired)
    : priceRequired;
};
