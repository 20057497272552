import React from 'react';
import { string, bool } from 'prop-types';
import { compose } from 'redux';
import { Form as FinalForm } from 'react-final-form';
import classNames from 'classnames';

import { FormattedMessage, injectIntl, intlShape } from '../../../util/reactIntl';
import * as validators from '../../../util/validators';
import { propTypes } from '../../../util/types';
import {
  Form,
  PrimaryButton,
  FieldTextInput,
  IconInquiry,
  Heading,
  Modal,
  FieldCurrencyInput,
} from '../../../components';

import css from './BidModal.module.css';
import appSettings from '../../../config/settings';
import { useConfiguration } from '../../../context/configurationContext';
import { getCustomPriceValidators } from '../../../util/listings';

const ErrorMessage = props => {
  const { error } = props;
  // No transaction process attached to listing
  return error ? (
    <p className={css.error}>
      {error.message === 'No transaction process attached to listing' ? (
        <FormattedMessage id="InquiryForm.sendInquiryErrorNoProcess" />
      ) : (
        <FormattedMessage id="InquiryForm.sendInquiryError" />
      )}
    </p>
  ) : null;
};

const BidModalComponent = props => {
  const { modalOpen, setModalOpen, listing } = props;
  const config = useConfiguration();
  return (
    <Modal
      isOpen={modalOpen}
      onClose={() => {
        setModalOpen(false);
      }}
      onManageDisableScrolling={() => {}}
    >
      <FinalForm
        {...props}
        render={fieldRenderProps => {
          const {
            rootClassName,
            className,
            submitButtonWrapperClassName,
            formId,
            handleSubmit,
            inProgress,
            intl,
            listingTitle,
            authorDisplayName,
            sendInquiryError,
          } = fieldRenderProps;

          const messageLabel = intl.formatMessage(
            {
              id: 'InquiryForm.messageLabel',
            },
            { authorDisplayName }
          );
          const messagePlaceholder = intl.formatMessage(
            {
              id: 'InquiryForm.messagePlaceholder',
            },
            { authorDisplayName }
          );
          const messageRequiredMessage = intl.formatMessage({
            id: 'InquiryForm.messageRequired',
          });
          const messageRequired = validators.requiredAndNonEmptyString(messageRequiredMessage);

          const classes = classNames(rootClassName || css.root, className);
          const submitInProgress = inProgress;
          const submitDisabled = submitInProgress;
          const priceValidators = getCustomPriceValidators(100, config.currency, intl);

          return (
            <Form
              className={classes}
              onSubmit={handleSubmit}
              enforcePagePreloadFor="OrderDetailsPage"
            >
              <Heading as="h2" rootClassName={css.heading}>
                {'Make an offer'}
              </Heading>
              <FieldCurrencyInput
                id={`bidPrice`}
                name="bidPrice"
                className={css.bidAmount}
                label={'Your offer'}
                placeholder={intl.formatMessage({
                  id: 'EditListingPricingForm.priceInputPlaceholder',
                })}
                currencyConfig={appSettings.getCurrencyFormatting(config.currency)}
                validate={priceValidators}
              />
              <div className={submitButtonWrapperClassName}>
                <ErrorMessage error={sendInquiryError} />
                <PrimaryButton
                  type="submit"
                  inProgress={submitInProgress}
                  disabled={submitDisabled}
                >
                  {'Send'}
                </PrimaryButton>
              </div>
            </Form>
          );
        }}
      />
    </Modal>
  );
};

BidModalComponent.defaultProps = {
  rootClassName: null,
  className: null,
  submitButtonWrapperClassName: null,
  inProgress: false,
  sendInquiryError: null,
};

BidModalComponent.propTypes = {
  rootClassName: string,
  className: string,
  submitButtonWrapperClassName: string,

  inProgress: bool,

  listingTitle: string.isRequired,
  authorDisplayName: string.isRequired,
  sendInquiryError: propTypes.error,

  // from injectIntl
  intl: intlShape.isRequired,
};

const BidModal = compose(injectIntl)(BidModalComponent);

BidModal.displayName = 'BidModal';

export default BidModal;
